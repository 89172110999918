import { Button, Text } from 'ui';
import { useEffect, useState } from 'react';
import { useTranslationScope } from '~/localization/use-translation-scope';

export function UnknownError() {
  const [traceId, setTraceId] = useState<string | null>(null);
  const { t } = useTranslationScope('error-pages');

  useEffect(() => {
    setTraceId(
      document.cookie
        .split(';')
        .find((c) => c.includes('errorTraceId'))
        ?.split('=')[1] ?? null,
    );
  }, []);

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="flex flex-col items-center gap-2 text-center">
        <h1 className="text-3xl font-bold">{t('error-pages:unknown-error')}</h1>
        <Text>{t('error-pages:could-not-complete-request')}</Text>
        {traceId ? (
          <Text>
            {t('error-pages:error-trace-id')}: {traceId}
          </Text>
        ) : null}
        <Button className="mt-8" href="https://www.moodys.com" id="error-page-go-to-moodys-home">
          {t('error-pages:go-to-moodys-home')}
        </Button>
      </div>
    </div>
  );
}
