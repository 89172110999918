import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { NotFoundError } from '~/components/errors/NotFoundError';
import { TooManyRequestsError } from '~/components/errors/TooManyRequestsError';
import { ForbiddenError } from '~/components/errors/ForbiddenError';
import { UnknownError } from '~/components/errors/UnknownError';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

export function DefaultErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error) && error.status === 404) {
    return <NotFoundError />;
  }

  if (!isRouteErrorResponse(error)) {
    captureRemixErrorBoundaryError(error);
  }

  if (typeof document !== 'undefined') {
    console.error(error);
  }

  if (isRouteErrorResponse(error) && error.status === 403) {
    return <ForbiddenError message={error.statusText} />;
  }

  if (isRouteErrorResponse(error) && error.status === 429) {
    return <TooManyRequestsError />;
  }

  return <UnknownError />;
}
