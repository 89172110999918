import { Button } from 'ui';
import { useTranslationScope } from '~/localization/use-translation-scope';

export function NotFoundError() {
  const { t } = useTranslationScope('error-pages');
  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="flex flex-col items-center text-center">
        <h4 className="text-h4">404</h4>
        <h1 className="text-h1">{t('error-pages:404-not-found')}</h1>
        <p className="text-gray-6 text-body my-4">{t('error-pages:404-could-not-find-page')}</p>
        <Button id="error-page-go-to-moodys-home" href="https://moodys.com">
          {t('error-pages:go-to-moodys-home')}
        </Button>
      </div>
    </div>
  );
}
