import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, useRouteLoaderData } from '@remix-run/react';
import twStyles from './tailwind.css?url';
import type { PropsWithChildren } from 'react';
import { DefaultErrorBoundary } from '~/components/errors/DefaultErrorBoundary';
import { useTranslation } from 'react-i18next';
import { useChangeLanguage } from 'remix-i18next/react';
import getDefaultLocale from '~/localization/get-default-locale.server';
import { withSentry } from '@sentry/remix';
import { getBrowserEnv } from './environment';
import type { HandleWithI18n } from './localization/use-translation-scope';
import { useNonce } from '~/utils/nonce-provider';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: 'https://rsms.me/inter/inter.css' },
  { rel: 'stylesheet', href: twStyles },
  { media: '(prefers-color-scheme: light)', rel: 'icon', href: '/images/favicon_light_mode.ico', type: 'image/x-icon' },
  { media: '(prefers-color-scheme: dark)', rel: 'icon', href: '/images/favicon_dark_mode.ico', type: 'image/x-icon' },
];

export const handle: HandleWithI18n = {
  // Set the namespace for the copy needed for this route
  i18n: ['login', 'form', 'error-pages'],
};

export async function loader({ request }: LoaderFunctionArgs) {
  let locale = await getDefaultLocale(request);
  const isMacOs = request.headers.get('user-agent')?.includes('Mac') ?? false;

  return json({ isMacOs, locale, browserEnv: getBrowserEnv() });
}

export function Layout({ children }: PropsWithChildren) {
  // Loader data can be null if we are in an error boundary
  const loaderData = useRouteLoaderData<typeof loader>('root');
  let { i18n } = useTranslation();

  const nonce = useNonce();

  const env = loaderData?.browserEnv ?? {};

  // This hook will change the language if the user takes some action to update it
  useChangeLanguage(loaderData?.locale ?? 'en');

  return (
    <html lang={loaderData?.locale ?? 'en'} dir={i18n.dir()} className="h-full bg-white text-[18px] antialiased">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <Meta />
        <Links />
        <script
          src="https://cdnjs.cloudflare.com/polyfill/v3/polyfill.min.js?version=4.8.0&features=es2023%2Ces2022%2Ces2021%2Ces2020%2Ces2018%2Ces2019%2Ces2017%2Ces2016%2Ces2015%2Cdefault%2Ces7%2Ces6%2Ces5"
          nonce={nonce}
        />
      </head>
      <body className="text-gray-10 h-full dark:text-white">
        {children}
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
          nonce={nonce}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <script
          src="https://challenges.cloudflare.com/turnstile/v0/api.js"
          nonce={nonce}
          defer
          id="cf-turnstile-script"
        />
      </body>
    </html>
  );
}

function App() {
  return <Outlet />;
}

export default withSentry(App);

export const ErrorBoundary = DefaultErrorBoundary;
